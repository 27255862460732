import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import "./Uploadvideo.scss";
import jems from "../Images/jems.png";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import axios from "axios";
import Spinners from "../../components/Spinner/Spinners";
import Spinner2 from "../../components/Spinner/Spinner2";

function Uploadvideo() {
  const [videoDescription, setVideoDescription] = useState({
    thumbUrl: "",
    title: "",
    desc: "",
    noOfGems: "",
    isPortrait: false,
    vdoUrl: "",
  });
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [file, setFile] = useState({ vdoUrl: "", thumbUrl: "" });
  const fileUpload = useRef();
  const videoUpload = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const handleVideoPublish = () => {
    if (videoDescription.vdoUrl == "") {
      setText(process.env.REACT_APP_MODAL_TEXT_UPLOADVIDEO);
      setOpen(true);
    } else if (videoDescription.thumbUrl == "") {
      setText(process.env.REACT_APP_MODAL_TEXT_UPLOADTHUBNAIL);
      setOpen(true);
    } else if (videoDescription.title.trim() == "") {
      setText(process.env.REACT_APP_MODAL_TEXT_ENTER_TITLE);
      setOpen(true);
    } else if (videoDescription.desc.trim() == "") {
      setText(process.env.REACT_APP_MODAL_TEXT_ENTER_DISCRIPTION);
      setOpen(true);
    } else if (videoDescription.noOfGems == null) {
      setText(process.env.REACT_APP_MODAL_TEXT_ENTER_GEMS);
      setOpen(true);
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      let axiosConfig = {
        headers: {
          "X-Auth-Email": process.env.REACT_APP_X_AUTH_EMAIL,
          "X-Auth-Key": process.env.REACT_APP_X_AUTH_KEY,
          Authorization: process.env.REACT_APP_AUTHORIZATION,
          "Content-Type": process.env.REACT_APP_CONTENT_TYPE,
          "Access-Control-Allow-Origin":
            process.env.REACT_APP_ACCESS_CONTROL_ALLOW_ORIGIN,
          Accept: process.env.REACT_APP_ACCEPT,
        },
      };
      let videopath = "";
      let webVdoUrl = "";

      axios
        .post(
          process.env.REACT_APP_VIDEO_UPLOAD_CLOUDFAIR_URL,
          { file: file.vdoUrl },
          axiosConfig
        )
        .then((response) => {
          console.log(response, "RESPONSE");
          if (response.data.success) {
            let uid = response.data.result.uid;
            console.log(uid, "UIDDDDD");
            videopath = `https://videodelivery.net/${uid}/manifest/video.m3u8`;
            webVdoUrl = `https://watch.videodelivery.net/${uid}`;
            // videopath = `${process.env.REACT_APP_VIDEOPATH_URL.replace(
            //   "${uid}",
            //   uid
            // )}`;
            // webVdoUrl = `${process.env.REACT_APP_WEBVIDEO_URL.replace(
            //   "${uid}",
            //   uid
            // )}`;
            console.log(videopath, "VideoPath");
            // return uid;
          }
        })
        .then((uid) => {
          // let constructedVideopath = videopath.replace("${uid}", uid);
          // let constructedVideopath1 = webVdoUrl.replace("${uid}", uid);
          axios
            .post(
              process.env.REACT_APP_IMAGE_UPLOAD_CLOUDFAIR_URL,
              { file: file.thumbUrl },
              axiosConfig
            )
            .then((response) => {
              if (response.data.success) {
                let imgID = response.data.result.id;
                let imagePath = `${process.env.REACT_APP_IMAGE_GET_CLOUDFAIR_URL1}${imgID}${process.env.REACT_APP_IMAGE_GET_CLOUDFAIR_URL2}`;
                addDoc(
                  collection(
                    db,
                    process.env.REACT_APP_VIDEO_UPLOAD_COLLECTION_NAME
                  ),
                  {
                    ...videoDescription,
                    noOfGems: parseInt(videoDescription.noOfGems),
                    createdAt: serverTimestamp(),
                    likeCounter: parseInt(
                      process.env.REACT_APP_UPLOAD_VIDEO_LIKE_COUNTER
                    ),
                    monitizeState:
                      process.env.REACT_APP_UPLOAD_VIDEO_MONITIZE_STATE,
                    thumbUrl: imagePath,
                    viewCount: parseInt(
                      process.env.REACT_APP_UPLOAD_VIDEO_VIEW_COUNTER
                    ),
                    webVdoUrl: webVdoUrl,
                    vdoUrl: videopath,
                  }
                );
                setIsLoading(false);
                setOpen(true);
                setText(process.env.REACT_APP_VIDEO_UPLOAD_SUCESSFULY_TEXT);
              }
            });
        });
    }
  };

  const close = () => {
    setVideoDescription({
      thumbUrl: "",
      title: "",
      desc: "",
      noOfGems: "",
      isPortrait: false,
      vdoUrl: "",
    });
    setFile({ vdoUrl: "", thumbUrl: "" });
    setOpen(false);
  };

  const preview = (e) => {
    setFile({ ...file, thumbUrl: e.target.files[0] });
    setVideoDescription({
      ...videoDescription,
      thumbUrl: URL.createObjectURL(e.target.files[0]),
    });
  };
  const videopreview = (e) => {
    setFile({ ...file, vdoUrl: e.target.files[0] });
    setVideoDescription({
      ...videoDescription,
      vdoUrl: URL.createObjectURL(e.target.files[0]),
    });
  };

  let arr = [
    {
      img: false,
      url: "https://encrypted-vtbn0.gstatic.com/video?q=tbn:ANd9GcS0WwcoS0cHitzAMTzrYfm-S5JhCHfFYJ8EFw",
    },
  ];
  return (
    <>
      <Box className="uvmain">
        <Box>
          <Box>
            <Typography variant="h6" id="uvdiv">
              {process.env.REACT_APP_UPLOAD_VIDEO_PAGE_HEADING}
            </Typography>
          </Box>
          <Box id="line" />
          <Box className="vbtndiv">
            <Button
              variant="contained"
              id="btn"
              onClick={() => videoUpload.current.click()}
            >
              {process.env.REACT_APP_GALLARY_BUTTON_TEXT}
              <input
                hidden
                accept="mp4/*,video/*"
                ref={videoUpload}
                multiple
                type="file"
                onChange={(e) => videopreview(e)}
              />
            </Button>
          </Box>
          <Box id="line" />
          <Box className="vformdiv">
            <Typography variant="h6" id="vformheadig">
              {process.env.REACT_APP_UPLOAD_VIDEO_PAGE_MEDIA_DETAILS_HEADING}
            </Typography>
            <Box className="vformimgdiv">
              {arr.map((val) => {
                return (
                  <>
                    {isLoading ? <Spinner2 /> : null}
                    {videoDescription.vdoUrl !== "" ? (
                      <Box className="boxvideo">
                        <video src={videoDescription.vdoUrl} className="img" />
                      </Box>
                    ) : (
                      <Typography variant="body1" id="vformimgheadig">
                        {
                          process.env
                            .REACT_APP_UPLOAD_VIDEO_PAGE_NO_VIDEO_SELECTED_HEADING
                        }
                      </Typography>
                    )}
                  </>
                );
              })}
            </Box>
            <Box>
              <Typography variant="h6" id="uvdiv">
                {process.env.REACT_APP_UPLOAD_VIDEO_PAGE_THUMBNAIL_HEADING}
              </Typography>
            </Box>
            <Box id="line" />
            <Box className="btndiv">
              <Button
                variant="contained"
                id="btn"
                onClick={() => fileUpload.current.click()}
              >
                {process.env.REACT_APP_GALLARY_BUTTON_TEXT}
                <input
                  hidden
                  accept="image/*"
                  ref={fileUpload}
                  multiple
                  type="file"
                  onChange={(e) => preview(e)}
                />
              </Button>
            </Box>
            <Box id="line" />
            <Box className="vformdiv">
              <Typography variant="h6" id="vformheadig">
                {process.env.REACT_APP_UPLOAD_PHOTO_MEDIA_DETAILS}
              </Typography>
              <Box className="vformimgdiv">
                {videoDescription.thumbUrl !== "" ? (
                  <Box className="boxvideo">
                    <img src={videoDescription.thumbUrl} className="img" />
                  </Box>
                ) : (
                  <Typography variant="body1" id="vformimgheadig">
                    {
                      process.env
                        .REACT_APP_UPLOAD_VIDEO_PAGE_NO_THUMBNAIL_SELECTED_HEADING
                    }
                  </Typography>
                )}
              </Box>
            </Box>
            <Box>
              <Grid container className="vbox" columnSpacing={{ xs: 1 }}>
                <Grid item xs={4}>
                  <Typography variant="body1" id="vformtext">
                    {process.env.REACT_APP_TITLE_TEXT}
                  </Typography>
                </Grid>
                <Grid item xs={8} className="uvinput">
                  <TextField
                    type="text"
                    placeholder={
                      process.env.REACT_APP_PLACEHOLDER_TEXT_ENTER_TITLE
                    }
                    onChange={(e) =>
                      setVideoDescription({
                        ...videoDescription,
                        title: e.target.value,
                      })
                    }
                    value={videoDescription.title}
                    variant="outlined"
                    focused={false}
                    id="vtextfild"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container className="vbox" columnSpacing={{ xs: 1 }}>
                <Grid item xs={4}>
                  <Typography variant="body2" id="vformtext">
                    {process.env.REACT_APP_DISCRIPTION_TEXT}
                  </Typography>
                </Grid>
                <Grid item xs={8} className="uvinput2">
                  <TextField
                    type="text"
                    placeholder={
                      process.env.REACT_APP_PLACEHOLDER_TEXT_ENTER_DISCRIPTION
                    }
                    variant="outlined"
                    focused={false}
                    id="vtextfild"
                    value={videoDescription.desc}
                    onChange={(e) =>
                      setVideoDescription({
                        ...videoDescription,
                        desc: e.target.value,
                      })
                    }
                    fullWidth
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>
              <Grid container className="vbox" columnSpacing={{ xs: 1 }}>
                <Grid item xs={4}>
                  <img alt="" src={jems} className="vformimg" />
                </Grid>
                <Grid item xs={8} className="uvinput">
                  <TextField
                    type="number"
                    placeholder={
                      process.env.REACT_APP_PLACEHOLDER_TEXT_ENTER_GEMS
                    }
                    variant="outlined"
                    onChange={(e) =>
                      setVideoDescription({
                        ...videoDescription,
                        noOfGems: e.target.value,
                      })
                    }
                    value={videoDescription.noOfGems}
                    focused={false}
                    id="vtextfild"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container className="vbox" columnSpacing={{ xs: 1 }}>
                <Grid item xs={4}></Grid>
                <Grid item xs={8}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={videoDescription.isPortrait}
                        onChange={(e) =>
                          setVideoDescription({
                            ...videoDescription,
                            isPortrait: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Video is in Portrait Mode"
                    id="chek"
                  />
                </Grid>
              </Grid>
              <Grid container className="vbox" columnSpacing={{ xs: 1 }}>
                <Grid item xs={4}></Grid>
                <Grid item xs={8}>
                  <Button
                    variant="contained"
                    id="vpublishbtn"
                    onClick={() => handleVideoPublish()}
                  >
                    {process.env.REACT_APP_PUBLISH_BUTTON_TEXT}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalComponent
        close={close}
        open={open}
        text={text}
        title={process.env.REACT_APP_DIALOG_APP_NAME}
      />
    </>
  );
}

export default Uploadvideo;
