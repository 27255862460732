import React, { useEffect, useState } from "react";
import AgoraRTC from "agora-rtc-sdk";
import { useLocation, useNavigate } from "react-router-dom";
import "./AudienceSideLive.scss";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import SendIcon from "@mui/icons-material/Send";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { db } from "../../firebaseConfig/firebaseConfig";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  TextField,
  Typography,
} from "@mui/material";
// import VideocamIcon from "@mui/icons-material/Videocam";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import logo from "../../assests/logo.png";
import eye from "../../assests/eye.png";
// import { HandymanOutlined, SetMealSharp } from "@mui/icons-material";
import Gifs from "../../components/gifs/Gifs";
import { DisplayImage, GifsData } from "../../configs/gifAssests";
import jems from "../../component/Images/jems.png";
import InfiniteScroll from "react-infinite-scroll-component";
import { ExpandLess } from "@mui/icons-material";

var rtc = {
  client: null,
  joined: false,
  published: false,
  localStream: null,
  remoteStreams: [],
  params: {},
};

function leaveEventHost(params) {
  rtc.client.unpublish(rtc.localStream, function (err) {
    console.log("publish failed");
    console.error(err);
  });
  rtc.client.leave(function (ev) {
    console.log(ev);
  });
}

function leaveEventAudience(params) {
  rtc.client.leave(
    function () {
      console.log("client leaves channel");
      //……
    },
    function (err) {
      console.log("client leave failed ", err);
      //error handling
    }
  );
}

function AudienceSideLive() {
  const location = useLocation();
  const navigate = useNavigate();
  const [liveData, setLiveData] = useState({});
  const [viewCount, setTotalViewCount] = useState(0);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [hide, setHide] = useState(false);
  const [popupText, setPopupText] = useState(false);
  const [liveChatText, setLiveChatText] = useState("");
  const [gifOpenModal, setGifOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [messges, setMessages] = useState([]);
  const [sentMessage, setSendMessage] = useState({ open: false, val: "" });
  const [wallet, setWallet] = useState();
  const [liveChatGifs, setLiveChatGifs] = useState([]);
  const [liveChat, setLiveChat] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [inSufficientWallet, setInsufficientWallet] = useState({
    open: false,
    val: "",
  });
  const [pressed, setPressed] = useState(false);
  const [testUser, setTestUser] = useState(
    JSON.parse(localStorage.getItem("testUser") ?? false)
  );

  useEffect(() => {
    console.log("href", window.history.state);
    joinChannel(location.state?.role);
    console.log(location.state.role);
    const q2 = doc(
      db,
      process.env.REACT_APP_WALLET_COLLECTION_NAME,
      user.idUser
    );
    getDoc(q2).then((response) => {
      const walletCollectionData = response?.data();
      setWallet(walletCollectionData);
    });
    console.log(location.state, "State Data");
  }, []);

  var option = {
    appID: process.env.REACT_APP_AGORA_APP_ID,
    channel: process.env.REACT_APP_AGORA_CHANNEL_NAME,
    uid: null,
    token: location.state?.accessToken,
    key: "",
    secret: "",
  };
  function joinChannel(role) {
    // Create a client
    console.log("option", option);
    rtc.client = AgoraRTC.createClient({ mode: "live", codec: "h264" });
    // Initialize the client
    rtc.client.init(
      option.appID,
      function () {
        console.log("init success");

        // Join a channel
        rtc.client.join(
          option.token ? option.token : null,
          option.channel,
          option.uid ? +option.uid : null,
          function (uid) {
            console.log(
              "join channel: " + option.channel + " success, uid: " + uid
            );
            rtc.params.uid = uid;

            if (role === "audience") {
              rtc.client.on("connection-state-change", function (evt) {
                console.log("audience", evt);
              });

              rtc.client.on("stream-added", function (evt) {
                var remoteStream = evt.stream;
                var id = remoteStream.getId();
                if (id !== rtc.params.uid) {
                  rtc.client.subscribe(remoteStream, function (err) {
                    console.log("stream subscribe failed", err);
                  });
                }
                console.log("stream-added remote-uid: ", id);
              });

              rtc.client.on("stream-removed", function (evt) {
                var remoteStream = evt.stream;
                var id = remoteStream.getId();
                console.log("stream-removed remote-uid: ", id);
              });

              rtc.client.on("stream-subscribed", function (evt) {
                var remoteStream = evt.stream;
                var id = remoteStream.getId();
                remoteStream.play("remote_video_");
                console.log("stream-subscribed remote-uid: ", id);
              });

              rtc.client.on("stream-unsubscribed", function (evt) {
                var remoteStream = evt.stream;
                var id = remoteStream.getId();
                remoteStream.pause("remote_video_");
                console.log("stream-unsubscribed remote-uid: ", id);
              });
            }
          },
          function (err) {
            console.error("client join failed", err);
          }
        );
      },
      (err) => {
        console.error(err);
      }
    );
  }

  if (liveData?.state == "finished") {
    navigate("/userlive", { replace: true });
  }
  const handleSendGif = (ele) => {
    if (ele.noOfGems !== 0) {
      if (wallet?.balance >= ele.noOfGems) {
        setSendMessage({ open: true, val: ele });
      } else {
        setInsufficientWallet({ open: true, val: ele });
      }
    } else {
      setSendMessage({ open: false, val: ele });
    }
    setGifOpenModal({ open: false });
  };
  useEffect(() => {
    let q2 = query(
      collection(
        db,
        process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
        location.state?.strimingid,
        process.env.REACT_APP_WHO_JOIN_LIVE_COLLECTION_NAME
      ),
      where("liveJoinStatus", "==", "join")
    );
    onSnapshot(q2, (querySnapshot) => {
      let liveJoinUsersArr = [];
      querySnapshot.forEach((doc) => {
        liveJoinUsersArr.push(doc.data());
      });

      // console.log("livestrimingpage",doc.data());
      setTotalViewCount(liveJoinUsersArr.length);
    });

    let q4 = query(
      collection(db, process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME),
      orderBy("createdOn", "desc"),
      limit(1)
    );
    onSnapshot(q4, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setLiveData(doc.data());
      });
    });
    getGifsUsersSend();
    getChatsMessages();
    window.scrollTo({ behavior: "smooth" });
  }, []);

  const getGifsUsersSend = () => {
    let q6 = query(
      collection(
        db,
        process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
        location.state?.strimingid,
        process.env.REACT_APP_LIVE_CHAT_GIFS_COLLECTION_NAME
      ),
      orderBy("sentOn", "desc")
    );
    onSnapshot(q6, (querySnapshot) => {
      console.log("hi");
      let gifsofUsers = [];
      querySnapshot.forEach((doc) => {
        gifsofUsers.push(doc.data());
      });

      setLiveChatGifs(gifsofUsers);
      console.log("gifsofUsers", gifsofUsers);
    });
  };

  const getChatsMessages = (lastDoc = null) => {
    let q3;
    if (lastDoc) {
      q3 = query(
        collection(
          db,
          process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
          location.state?.strimingid,
          process.env.REACT_APP_LIVE_CHAT_MESSAGES_COLLECTION_NAME
        ),
        orderBy("createdAt", "desc"),
        startAfter(lastDoc),
        limit(15)
      );
    } else {
      q3 = query(
        collection(
          db,
          process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
          location.state?.strimingid,
          process.env.REACT_APP_LIVE_CHAT_MESSAGES_COLLECTION_NAME
        ),
        orderBy("createdAt", "desc"),
        limit(15)
      );
    }
    onSnapshot(q3, (querySnapshot) => {
      let chats = [];
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      querySnapshot.forEach((doc) => {
        chats.push(doc.data());
      });
      chats.reverse();
      setLiveChat([...chats, ...liveChat]);
    });
  };

  const handleSendMessages = (ele) => {
    let chatMessage = {
      gifPath: 0,
      giphyName: ele.name.replace(".jpg", ""),
      noOfGems: ele.noOfGems,
      sentOn: serverTimestamp(),
      uid: user.idUser,
      senderName: user.name,
    };
    console.log(chatMessage);

    let updatedWallet = {
      ...wallet,
      balance: wallet?.balance - chatMessage.noOfGems,
    };
    setWallet(updatedWallet);
    localStorage.setItem(
      process.env.REACT_APP_WALLET_LOCALSTORAGE,
      JSON.stringify(updatedWallet)
    );

    const querySnapshot = doc(
      db,
      process.env.REACT_APP_WALLET_COLLECTION_NAME,
      user.idUser
    );
    updateDoc(querySnapshot, {
      balance: updatedWallet.balance,
    }).then(() => {});

    addDoc(
      collection(
        db,
        process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
        location.state?.strimingid,
        process.env.REACT_APP_LIVE_CHAT_GIFS_COLLECTION_NAME
      ),
      chatMessage
    ).then(async (result) => {
      if (!testUser) {
        let userTransactionnsTable = {
          dm_message_gif: "",
          dm_message_image: "",
          dm_message_text: "",
          live_session_purchase_gif_name: ele.name,
          live_session_ref_id: location.state?.strimingid,
          live_session_purchase_comment_message: "",
          platform: process.env.REACT_APP_PLATFORM,
          purchased_media_description: "",
          purchased_media_title: "",
          purchased_media_url: "",
          purchased_product_description: "",
          purchased_product_title: "",
          recharge_gems_transaction_amount: "",
          recharge_gems_transaction_id: "",
          transaction_gems: chatMessage.noOfGems,
          transaction_gems_string: `-${chatMessage.noOfGems}`,
          transaction_ref_id: result.id,
          transaction_time: serverTimestamp(),
          transaction_type_id:
            process.env.REACT_APP_SENT_GIF_IN_LIVE_STRMINING_TYPE_ID,
          transaction_type_name:
            process.env.REACT_APP_SENT_GIF_IN_LIVE_STRMINING_TYPE_NAME,
          updated_balance_after_transaction: `${updatedWallet.balance}`,
          user_email: user.email,
          user_id: user.idUser,
          user_name: user.name,
          version: process.env.REACT_APP_VERSION,
        };
        console.log(userTransactionnsTable);
        await addDoc(
          collection(db, process.env.REACT_APP_USERTRANSACTIONS_COLLECTION),
          userTransactionnsTable
        ).then(() => {
          console.log("user transaction updated.");
        });
      }
    });
    setGifOpenModal(false);
    setSendMessage({ open: false, val: "" });
  };

  const handleUserExit = () => {
    let userExitFormLive = query(
      collection(
        db,
        process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
        location.state?.strimingid,
        process.env.REACT_APP_WHO_JOIN_LIVE_COLLECTION_NAME
      ),
      where("uid", "==", user.idUser)
    );
    getDocs(userExitFormLive).then((result) => {
      result.forEach(async (result) => {
        console.log("exit", result.id);
        console.log("sfd", location.state?.strimingid);
        await updateDoc(
          doc(
            db,
            process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
            location.state.strimingid,
            process.env.REACT_APP_WHO_JOIN_LIVE_COLLECTION_NAME,
            result.id
          ),
          { liveJoinStatus: "exit" }
        ).then(() => {
          console.log("userUpdated");
          navigate("/userlive", { replace: true });
        });
      });
    });
  };

  const handleSendChatText = () => {
    let chatMessage = {
      chatMessage: liveChatText.trimStart(),
      createdAt: serverTimestamp(),
      name: user.name,
      email: user.email,
      idUser: user.idUser,
      isRead: process.env.REACT_APP_LIVE_CHAT_MESSAGES_ISREAD,
      sentBy: process.env.REACT_APP_DM_FROM_SENDER,
      urlAvatar: user.urlAvatar,
    };
    setLiveChatText("");
    let updatedWallet = {
      ...wallet,
      balance: wallet?.balance - location.state.gemsForUserComment,
    };
    setWallet(updatedWallet);
    localStorage.setItem(
      process.env.REACT_APP_WALLET_LOCALSTORAGE,
      JSON.stringify(updatedWallet)
    );

    addDoc(
      collection(
        db,
        process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
        location.state?.strimingid,
        process.env.REACT_APP_LIVE_CHAT_MESSAGES_COLLECTION_NAME
      ),
      chatMessage
    ).then((result) => {
      console.log("chat messages added sucessfully!");
      setSendMessage({
        open: false,
        val: "",
      });
      if (!testUser) {
        let userTransactionnsTable = {
          dm_message_gif: "",
          dm_message_image: "",
          dm_message_text: "",
          live_session_purchase_gif_name: "",
          live_session_purchase_comment_message: chatMessage.chatMessage,
          live_session_ref_id: location.state?.strimingid,
          platform: process.env.REACT_APP_PLATFORM,
          purchased_media_description: "",
          purchased_media_title: "",
          purchased_media_url: "",
          purchased_product_description: "",
          purchased_product_title: "",
          recharge_gems_transaction_amount: "",
          recharge_gems_transaction_id: "",
          transaction_gems: location.state.gemsForUserComment,
          transaction_gems_string: `-${location.state.gemsForUserComment}`,
          transaction_ref_id: result.id,
          transaction_time: serverTimestamp(),
          transaction_type_id:
            process.env.REACT_APP_COMMENT_IN_LIVE_STRMINING_TYPE_ID,
          transaction_type_name:
            process.env.REACT_APP_COMMENT_IN_LIVE_STRMINING_TYPE_NAME,
          updated_balance_after_transaction: `${updatedWallet.balance}`,
          user_email: user.email,
          user_id: user.idUser,
          user_name: user.name,
          version: process.env.REACT_APP_VERSION,
        };
        console.log(userTransactionnsTable);

        addDoc(
          collection(db, process.env.REACT_APP_USERTRANSACTIONS_COLLECTION),
          userTransactionnsTable
        ).then(() => {
          console.log("user transaction updated.");
        });
      }
    });
  };

  const handleBalanceToSendComment = () => {
    if (location.state.gemsForUserComment <= wallet?.balance) {
      setSendMessage({
        open: true,
        val: "chat",
      });
    } else {
      setInsufficientWallet({ open: true, val: "chat" });
    }
  };

  return (
    <div onClick={() => (gifOpenModal ? setGifOpenModal(false) : null)}>
      <Box className="hh">
        <Box className="header">
          <Grid container className="headbox">
            <Grid item xs={1.5}>
              <Box className="menubtn">
                <ArrowBackIcon
                  style={{ color: "var(--white)" }}
                  onClick={() => setOpen(true)}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <img alt="" src={logo} className="adminLogo" />
            </Grid>
            <Grid
              item
              xs={1.5}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <img
                alt=""
                src={eye}
                className="adminLogo"
                style={{ width: "30px", height: "30px", aspectRatio: "1/1" }}
              />
              <Typography>{viewCount}</Typography>
            </Grid>
          </Grid>
          <Box className="cmline"></Box>
        </Box>
        {liveChatGifs.length > 0 && !hide && (
          <Box
            className="gif-box"
            style={{
              display: "flex",
              position: "fixed",
              top: "65px",
              overflow: "scroll",
              left: "0px",
              width: "100vw",
              zIndex: "500",
              padding: "10px",
            }}
          >
            {liveChatGifs.map((ele) => {
              let gifSrc = {};
              let timeString = `${ele.sentOn?.toDate().toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}`;
              GifsData.map((gif) => {
                if (gif.name.replaceAll(".jpg", "") === ele.giphyName) {
                  gifSrc = { ...gif };
                }
              });
              console.log(gifSrc, "abc");
              return (
                <div
                  style={{
                    width: "15vw",
                    minWidth: "15vw",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginRight: "20px",
                  }}
                >
                  <DisplayImage
                    src={gifSrc}
                    style={{
                      width: "inherit",
                      aspectRatio: "1/1",
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  />

                  <Typography className="text-align-center font-size text-shadow-typography">
                    {ele.senderName}
                  </Typography>
                  <Typography className="text-align-center font-size text-shadow-typography">
                    {timeString}
                  </Typography>
                </div>
              );
            })}
          </Box>
        )}
        <Box
          style={{
            position: "fixed",
            bottom: "75px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            zIndex: "1000",
            width: "100vw",
          }}
        >
          {!hide && (
            <Button
              className="typography-loginpage loginpage-button input-field-shadow"
              style={{
                zIndex: "1000",
                backgroundColor: "var(--trnaparentPink1)",
                color: "var(--white)",
                height: "38px",
                borderRadius: "18px",
                border: "1px solid var(--white)",
                textTransform: "none",
                marginBottom: "15px",
              }}
              onClick={() => setHide(true)}
            >
              <span style={{ color: "var(--white)" }}>Tap to hide</span>
            </Button>
          )}
          {liveChat.length > 0 && !hide && location.state.commentsFlag && (
            <Box
              id="chatBox1"
              className="chatBox"
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "auto",
              }}
            >
              <InfiniteScroll
                dataLength={liveChat.length}
                next={() => {
                  getChatsMessages(lastDoc);
                }}
                style={{ display: "flex", flexDirection: "column" }}
                scrollableTarget="chatBox1"
                hasMore={true}
                scrollThreshold={"300px"}
                initialScrollY={0}
              >
                {liveChat &&
                  liveChat.map((ele) => {
                    return (
                      <>
                        <div
                          className="chatmessages"
                          style={{
                            display: "flex",
                            width: "calc(100vw - 10px)",
                            paddingLeft: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <img
                            className="input-field-shadow"
                            src={ele.urlAvatar}
                            style={{
                              width: "40px",
                              aspectRatio: "1/1",
                              height: "40px",
                              borderRadius: "50%",
                              border: "1px solid var(--white)",
                            }}
                          />
                          <Box style={{ marginLeft: "10px" }}>
                            <Typography className="text-shadow-typography chat-font-size">
                              {ele.name}
                            </Typography>
                            <Typography className="text-shadow-typography chat-font-size">
                              {ele.chatMessage}
                            </Typography>
                          </Box>
                        </div>
                      </>
                    );
                  })}
              </InfiniteScroll>
            </Box>
          )}
        </Box>
        <Box className="audience-side-live">
          <div
            id="remote_video_"
            style={{
              width: "100vw",
              position: "absolute",
              top: "64px",
              height: "calc(100vh - 64px)",
              overflow: "hidden",
            }}
          />
          {location.state?.role == "audience" && !hide && (
            <Box
              style={{
                display: "flex",
                position: "fixed",
                bottom: "10px",
                width: "calc(100vw - 20px)",
                justifyContent: "space-between",
                padding: "10px",
                alignItems: "center",
                borderRadius: "25px",
              }}
            >
              {location.state.commentsFlag && (
                <>
                  <TextField
                    focused={false}
                    placeholder="Type your message"
                    value={liveChatText}
                    onChange={(e) => setLiveChatText(e.target.value)}
                    style={{
                      borderRadius: "25px",
                      outline: "none !important",
                      width: "70vw",
                      backgroundColor: "var(--trnaparentBlack)",
                    }}
                  />
                  <Box className="dmc" onClick={() => setGifOpenModal(true)}>
                    <CardGiftcardIcon onClick={() => setPopupText(true)} />
                  </Box>
                  <Box
                    className="dma"
                    onClick={() =>
                      liveChatText.trim() !== ""
                        ? handleBalanceToSendComment()
                        : null
                    }
                  >
                    <SendIcon
                      style={{ color: "var(--white) !important" }}
                      onClick={() => setPopupText(false)}
                    />
                  </Box>
                </>
              )}
              {/* {!location.state.commentsFlag && (
                <Button
                  onClick={() => setGifOpenModal(true)}
                  style={{
                    backgroundColor: "var(--sendStrickerButtonBackground)",
                    width: "100%",
                    borderRadius: "10px",
                    height: "40px",
                  }}
                >
                  <span
                    style={{
                      color: "var(--white)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <CardGiftcardIcon
                      style={{ color: "var(--white) !important" }}
                    />
                    &nbsp;&nbsp;&nbsp;Send Strickers{" "}
                  </span>
                </Button>
              )} */}
            </Box>
          )}
        </Box>
      </Box>
      {gifOpenModal && (
        <Gifs
          open={gifOpenModal}
          close={(ele) => handleSendGif(ele)}
          color="transparent"
        />
      )}
      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="responsive-dialog-title "
          className="loguotpop"
        >
          <DialogTitle className="dialog-title-background typography-loginpage">
            {process.env.REACT_APP_EXIT_DIALOG_NAME}
          </DialogTitle>

          <DialogContent>
            <DialogContentText className="typography-loginpage">
              {process.env.REACT_APP_LEAVE_SESSION_LIVE1}
            </DialogContentText>
            <DialogContentText className="typography-loginpage">
              {process.env.REACT_APP_LEAVE_SESSION_LIVE2}
            </DialogContentText>
            <Button
              className="typography-loginpage loginpage-button"
              onClick={() => handleUserExit()}
            >
              {process.env.REACT_APP_YES_BUTTON1}
            </Button>
            <Button
              className="typography-loginpage loginpage-button"
              onClick={() => setOpen(false)}
            >
              {process.env.REACT_APP_NO_BUTTON}
            </Button>
          </DialogContent>
        </Dialog>
      )}
      {sentMessage.open && (
        <Dialog
          open={sentMessage.open}
          onClose={() => setSendMessage({ open: false, val: "" })}
          className="photopopup"
          aria-labelledby="draggable-dialog-title"
        >
          <DialogContent id="context">
            <DialogContentText className="context-div">
              <Typography variant="h6" id="modeltext">
                {process.env.REACT_APP_WALLET_BALANCE_TEXT}:{wallet?.balance}
              </Typography>
              <img alt="" src={jems} className="jemsimg" />
            </DialogContentText>
          </DialogContent>
          <DialogActions className="modelbtndiv">
            <Button
              variant="contained"
              onClick={() =>
                sentMessage?.val == "chat"
                  ? handleSendChatText()
                  : handleSendMessages(sentMessage.val)
              }
              id="popbtn"
            >
              {process.env.REACT_APP_BUY_FOR_TEXT} &nbsp;
              {/* {sentMessage.val.noOfGems} */}
              {popupText && sentMessage.val.noOfGems}
              {!popupText && location.state?.gemsForUserComment}
              <img alt="" src={jems} className="jemsimg" />
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setSendMessage({ open: false, val: "" });
                setGifOpenModal(false);
              }}
              id="popbtn"
            >
              {process.env.REACT_APP_MODAL_CANCEL_BUTTON_TEXT}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {hide && (
        <Box className="show-button">
          <Box onClick={() => setHide(false)} className="back-icon-box">
            <ExpandLess style={{ color: "var(--white)" }} />
          </Box>
        </Box>
      )}
      {inSufficientWallet.open && (
        <Dialog
          open={inSufficientWallet.open}
          onClose={() => inSufficientWallet({ open: false, val: "" })}
          className="photopopup"
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle id="modeltext" className="context-div">
            {process.env.REACT_APP_INSUFFICIENT_BALANCE}
          </DialogTitle>
          <Divider style={{ backgroundColor: "var(--white)" }} />

          <DialogContent id="context">
            <DialogContentText className="context-div">
              <Typography variant="h6" id="modeltext">
                {process.env.REACT_APP_WALLET_BALANCE_TEXT}: {wallet?.balance}
              </Typography>
              <img alt="" src={jems} className="jemsimg" />
            </DialogContentText>
          </DialogContent>

          <DialogContent id="context">
            <DialogContentText className="context-div">
              <Typography variant="h6" id="modeltext">
                {process.env.REACT_APP_NEED_GEMS_FOR_TEXT}:{" "}
                {inSufficientWallet.val == "chat"
                  ? location.state?.gemsForUserComment
                  : parseInt(location.state.gemsForUserComment) -
                    wallet?.balance}
              </Typography>
              <img alt="" src={jems} className="jemsimg" />
            </DialogContentText>
          </DialogContent>

          <DialogActions className="modelbtndiv">
            <Button
              variant="contained"
              id="popbtn"
              onClick={() =>
                navigate("/mainback/rechargepage", { replace: true })
              }
            >
              {process.env.REACT_APP_BUY_FOR_TEXT}{" "}
              {inSufficientWallet.val == "chat"
                ? location.state?.gemsForUserComment
                : location.state.gemsForUserComment}
              <img alt="" src={jems} className="jemsimg" />
            </Button>

            <Button
              variant="contained"
              onClick={() => setInsufficientWallet({ open: false, val: "" })}
              id="popbtn"
            >
              {process.env.REACT_APP_MODAL_CANCEL_BUTTON_TEXT}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default AudienceSideLive;
