import React, { useEffect } from "react";
import "./VideosPage.scss";
import { useLocation } from "react-router-dom";

const VideosPage = () => {
  const im = useLocation();
  console.log(im.state.val.vdoUrl, "VideoURLLL");
  return (
    <div
      style={{
        backgroundColor: "var(--black)",
        width: "100vw",
        height: "100vh",
      }}
    >
      <video
        src={im.state.val.vdoUrl}
        autoplay="true"
        preload="auto"
        controls
        style={
          !im.state.val?.isPortrait
            ? {
                transform: "rotate(-90deg)",
                transformOrigin: "left top",
                background: "var(--black)",

                width: "100vh",
                height: "100vw",
                overflowX: "hidden",
                position: "absolute",
                left: 0,
                top: "100vh",
                bottom: 0,
              }
            : { width: "100%", height: "100vh" }
        }
      ></video>
    </div>
  );
};

export default VideosPage;
