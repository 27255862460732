import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import LiveImage from "../../assests/livePlaceholder.jpg";
import { useNavigate } from "react-router-dom";
import AgoraRTC from "agora-rtc-sdk";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import ModalforPurchase from "../Photopage/ModalforPurchase";

const LiveStrimingPage = (props) => {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [liveData, setLiveData] = useState({});
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [joinedUser, setJoiedUser] = useState({});
  const [open, setOpen] = useState({ open: false, val: "" });
  const [wallet, setWallet] = useState();
  const [testUser, setTestUser] = useState(
    JSON.parse(localStorage.getItem("testUser") ?? false)
  );

  useEffect(() => {
    let q4 = query(
      collection(db, process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME),
      orderBy("createdOn", "desc"),
      limit(1)
    );
    onSnapshot(q4, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setLiveData({ ...doc.data(), liveStrimingDocId: doc.id });
      });
    });
    const q2 = doc(
      db,
      process.env.REACT_APP_WALLET_COLLECTION_NAME,
      user.idUser
    );
    getDoc(q2).then((response) => {
      const walletCollectionData = response?.data();
      setWallet(walletCollectionData);
    });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  var rtc = {
    client: null,
    joined: false,
    published: false,
    localStream: null,
    remoteStreams: [],
    params: {},
  };

  // Options for joining a channel
  var option = {
    appID: process.env.REACT_APP_AGORA_APP_ID,
    channel: process.env.REACT_APP_AGORA_CHANNEL_NAME,
    uid: null,
    token: liveData.accessToken,
    key: "",
    secret: "",
  };
  function joinChannel(role) {
    // Create a client
    rtc.client = AgoraRTC.createClient({ mode: "live", codec: "h264" });
    // Initialize the client
    rtc.client.init(
      option.appID,
      function () {
        console.log("init success");

        // Join a channel
        rtc.client.join(
          option.token ? option.token : null,
          option.channel,
          option.uid ? +option.uid : null,
          function (uid) {
            console.log(
              "join channel: " + option.channel + " success, uid: " + uid
            );
            rtc.params.uid = uid;
            if (role === "host") {
              rtc.client.setClientRole("host");
              // Create a local stream
              rtc.localStream = AgoraRTC.createStream({
                streamID: rtc.params.uid,
                audio: true,
                video: true,
                screen: false,
              });

              // Initialize the local stream
              rtc.localStream.init(
                function () {
                  console.log("init local stream success");
                  rtc.localStream.play("local_stream");
                  rtc.client.publish(rtc.localStream, function (err) {
                    console.log("publish failed");
                    console.error(err);
                  });
                },
                function (err) {
                  console.error("init local stream failed ", err);
                }
              );

              rtc.client.on("connection-state-change", function (evt) {
                console.log("audience", evt);
              });
            }
            if (role === "audience") {
              rtc.client.on("connection-state-change", function (evt) {
                console.log("audience", evt);
              });

              rtc.client.on("stream-added", function (evt) {
                var remoteStream = evt.stream;
                var id = remoteStream.getId();
                if (id !== rtc.params.uid) {
                  rtc.client.subscribe(remoteStream, function (err) {
                    console.log("stream subscribe failed", err);
                  });
                }
                console.log("stream-added remote-uid: ", id);
              });

              rtc.client.on("stream-removed", function (evt) {
                var remoteStream = evt.stream;
                var id = remoteStream.getId();
                console.log("stream-removed remote-uid: ", id);
              });

              rtc.client.on("stream-subscribed", function (evt) {
                var remoteStream = evt.stream;
                var id = remoteStream.getId();
                remoteStream.play("remote_video_");
                console.log("stream-subscribed remote-uid: ", id);
              });

              rtc.client.on("stream-unsubscribed", function (evt) {
                var remoteStream = evt.stream;
                var id = remoteStream.getId();
                remoteStream.pause("remote_video_");
                console.log("stream-unsubscribed remote-uid: ", id);
              });
            }
          },
          function (err) {
            console.error("client join failed", err);
          }
        );
      },
      (err) => {
        console.error(err);
      }
    );
  }

  const getUserJoinInfo = () => {
    console.log(props.liveData.liveStrimingDocId);
    let q1 = query(
      collection(
        db,
        process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
        props.liveData?.liveStrimingDocId,
        process.env.REACT_APP_WHO_JOIN_LIVE_COLLECTION_NAME
      ),
      where("uid", "==", user.idUser),
      limit(1)
    );
    onSnapshot(q1, (querySnapshot) => {
      querySnapshot.forEach((result) => {
        setJoiedUser({ ...result.data(), userJoinId: result.id });
        console.log("livestrimingpage", result.data());
      });
    });
  };

  useEffect(() => {
    getUserJoinInfo();
  }, []);

  const handleprejoinqueries = () => {
    if (Object.keys(joinedUser).length > 0) {
      joinChannel("audience");
      updateDoc(
        doc(
          db,
          process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
          props.liveData?.liveStrimingDocId,
          process.env.REACT_APP_WHO_JOIN_LIVE_COLLECTION_NAME,
          joinedUser.userJoinId
        ),
        { liveJoinStatus: "join" }
      );
      navigate("/livestrmingpage", {
        state: {
          role: "audience",
          strimingid: props.liveData?.liveStrimingDocId,
          accessToken: props.liveData?.accessToken,
          commentsFlag: props.liveData?.enableCommentsInLiveStreaming,
          gemsForUserComment: props.liveData?.gemForUserComment,
        },
      });
    } else {
      setOpen({ open: true, val: props.liveData });
    }
  };
  const handlePurchase = (val) => {
    if (Object.keys(joinedUser).length == 0) {
      let updatedWallet = {
        ...wallet,
        balance: wallet?.balance - props.liveData?.noOfGems,
      };
      setWallet(updatedWallet);
      localStorage.setItem(
        process.env.REACT_APP_WALLET_LOCALSTORAGE,
        JSON.stringify(updatedWallet)
      );

      const querySnapshot = doc(
        db,
        process.env.REACT_APP_WALLET_COLLECTION_NAME,
        user.idUser
      );
      updateDoc(querySnapshot, {
        balance: updatedWallet.balance,
      }).then(() => {});
      if (!testUser) {
        console.log(" Not Test User");
        let userTransactionnsTable = {
          dm_message_gif: "",
          dm_message_image: "",
          dm_message_text: "",
          live_session_purchase_gif_name: "",
          live_session_purchase_comment_message: "",

          live_session_ref_id: props.liveData?.liveStrimingDocId,
          platform: process.env.REACT_APP_PLATFORM,
          purchased_media_description: "",
          purchased_media_title: "",
          purchased_media_url: "",
          purchased_product_description: "",
          purchased_product_title: "",
          recharge_gems_transaction_amount: "",
          recharge_gems_transaction_id: "",
          transaction_gems: props.liveData?.noOfGems,
          transaction_gems_string: `-${props.liveData?.noOfGems}`,
          transaction_ref_id: props.liveData?.liveStrimingDocId,
          transaction_time: serverTimestamp(),
          transaction_type_id: "5",
          transaction_type_name: "joined_live_streaming",
          updated_balance_after_transaction: `${updatedWallet.balance}`,
          user_email: user.email,
          user_id: user.idUser,
          user_name: user.name,
          version: process.env.REACT_APP_VERSION,
        };
        addDoc(
          collection(db, process.env.REACT_APP_USERTRANSACTIONS_COLLECTION),
          userTransactionnsTable
        ).then(() => {});
      }
      let liveJoinUser = {
        liveJoinStatus: "join",
        liveSessionId: props.liveData?.liveStrimingDocId,
        liveSessionJoinAt: serverTimestamp(),
        noOfGems: props.liveData?.noOfGems,
        platform: process.env.REACT_APP_PLATFORM,
        uid: user.idUser,
        version: process.env.REACT_APP_VERSION,
      };

      addDoc(
        collection(
          db,
          process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
          props.liveData?.liveStrimingDocId,
          process.env.REACT_APP_WHO_JOIN_LIVE_COLLECTION_NAME
        ),
        liveJoinUser
      );
    }
    joinChannel("audience");
    let comment = props.liveData?.enableCommentsInLiveStreaming ?? false;

    navigate("/livestrmingpage", {
      state: {
        role: "audience",
        strimingid: props.liveData?.liveStrimingDocId,
        accessToken: props.liveData?.accessToken,
        commentsFlag: comment,
        gemsForUserComment: props.liveData?.gemForUserComment,
      },
    });
    setOpen({ open: false, val: "" });
  };
  return (
    <>
      <Box
        style={{
          backgroundColor: "var(--pink)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Button
          variant="contained"
          className="livebtn text-shadow-typography"
          style={{
            marginTop: "62px",
            backgroundColor: "var(--pinkDark)",
            textTransform: "none",
            width: "90vw",
          }}
          onClick={() => {
            handleprejoinqueries(props.liveData);
          }}
        >
          {process.env.REACT_APP_LIVE_BUTTON}
        </Button>
      </Box>
      <img
        src={LiveImage}
        style={{ height: "-webkit-fill-available", width: "100vw" }}
      />
      {open.open && (
        <ModalforPurchase
          open={open.open}
          val={open}
          wallet={wallet}
          close={() => handleClose()}
          handlePurchase={(ele) => handlePurchase(ele)}
        />
      )}
    </>
  );
};

export default LiveStrimingPage;
